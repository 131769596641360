import { graphql } from 'gatsby';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import Markdown from 'markdown-to-jsx';
import React from 'react';

// Components
import { EmptyTable, FilterBar } from 'components';

// Layouts
import { PageLayout, SEOContent } from 'layouts';

// Templates
import { BasicTemplate, RecordsTrackTable } from 'templates';

// Types
import { MeetName, Record, Season } from 'src/types';
import { orderBySeason } from 'utils';
interface Props {
  data: {
    trackRecords: Record;
    pageContent: any;
  };
  pageContext: {
    meetName: MeetName;
    season: Season;
  };
}

const FILTERS = [{ title: 'Gender', gqlKey: 'gender' }];

const IndoorTrackAndFieldRecordsTablePage = (props: Props) => {
  const { season, meetName } = props.pageContext;
  const { pageContent } = props.data;

  // Match the key name from QGL.
  const properSeason = camelCase(season);

  // Check for empty records.
  const records = get(props.data, properSeason, []) || [];
  const noRecords = records.length < 1;

  // Gather markdown content.
  const markdown = get(pageContent, 'nodes[0].data.markdown_content', '') || '';

  const templateTitle = `${startCase(season)} ${meetName} Records`;

  return (
    <SEOContent title={templateTitle}>
      <PageLayout>
        <BasicTemplate templateTitle={templateTitle}>
          {noRecords ? (
            <EmptyTable />
          ) : (
            <>
              <Markdown>{markdown}</Markdown>
              <FilterBar
                data={records}
                filters={FILTERS}
                render={tableData => (
                  <RecordsTrackTable
                    data={orderBySeason({ tableData, season })}
                  />
                )}
              />
            </>
          )}
        </BasicTemplate>
      </PageLayout>
    </SEOContent>
  );
};

export default IndoorTrackAndFieldRecordsTablePage;

export const query = graphql`
  query($meetName: String) {
    pageContent: allAirtable(filter: { table: { eq: "Record Table Page" } }) {
      nodes {
        data {
          markdown_content
          sponsors {
            data {
              sponsor_name
              sponsor_url
              attachments {
                localFiles {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    outdoorTrack: allAirtable(
      filter: {
        table: { eq: "Outdoor Track And Field Records" }
        data: { meet_name: { eq: $meetName } }
      }
    ) {
      nodes {
        data {
          record
          event
          record_holder_or_holders
          record_holder_image {
            thumbnails {
              large {
                url
              }
            }
          }
          year
          team
          meet_name
          result
          gender
          meet_location
        }
      }
    }
    indoorTrack: allAirtable(
      filter: {
        table: { eq: "Indoor Track And Field Records" }
        data: { meet_name: { eq: $meetName } }
      }
    ) {
      nodes {
        data {
          record
          event
          record_holder_or_holders
          record_holder_image {
            thumbnails {
              large {
                url
              }
            }
          }
          year
          team
          meet_name
          result
          gender
          meet_location
        }
      }
    }
  }
`;
